<template>
  <v-container fluid>
    <!-- <v-layout row wrap> -->
    <v-row justify="center" mb-4 mt-2>
      <v-col>
        <h1>Equipos Migps</h1>
        <h3>Asocia IMEI a un Order Id</h3>
      </v-col>
      <v-col justify="right" mb-4 mt-2>
        <v-col>
          <v-img src="../assets/migps.png" width="150" height="100"> </v-img>
        </v-col>
      </v-col>
    </v-row>

    <!-- </v-layout> -->
    <hr />
    <v-row justify="center">
      <v-col cols="6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="OrderId"
            :rules="OrderIdRules"
            label="Order Id"
            required
          ></v-text-field>

          <v-text-field
            v-model="equipoMigps"
            :rules="equipoMigpsRules"
            label="Equipo(s)"
            required
          ></v-text-field>

          <v-btn
            color="success"
            class="mr-4 mt-5"
            @click="agregarEquipoMigps"
            :disabled="deshabilitar"
            :loading="sendingRequest"
          >
            Asociar Imei(s)
          </v-btn>

          <v-btn color="warning" class="mr-4 mt-5" @click="reset">
            Limpiar
          </v-btn>

          <div class="mt-5 mx-5">
            <v-alert
              :value="alerta"
              type="success"
              transition="scale-transition"
              outlined
            >
              Equipo(s) asociado(s)! Nueva etiqueta generada.
            </v-alert>
            <v-alert
              :value="alertaNegativa"
              type="error"
              transition="scale-transition"
              outlined
            >
              No se encuentra pedido. Verifica que se esté escrito
              correctamente!
            </v-alert>
          </div>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="arregloFulFillment"
          :loading="loading"
        >
          <template v-slot:items="props">
            <!-- <td :style="{ 'background-color': props.item.color }"> -->
            <td :style="{ 'background-color': '#ffffff' }">
              <strong>{{ props.item.orderID }}</strong>
            </td>
            <td>{{ props.item.items }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data: () => ({
    sendingRequest: false,
    alerta: false,
    alertaNegativa: false,
    alertaPdf: false,
    valid: false,
    loaderPdf: false,
    loading: false,
    loadingTable: false,
    dialog: false,
    dialogoPdf: false,
    etiquetas: "",
    OrderId: "",
    cliente: "",
    clienteEtiqueta: "",
    tipoEtiqueta: "",
    tipoRules: [(v) => !!v || "Tipo de entrega es requerida"],
    clientesRules: [(v) => !!v || "Cliente es requerido"],
    OrderIdRules: [
      (v) => !!v || "Order Id es requerido",
      (v) => (v && v.length >= 5) || "OrderId debe ser mayor de 5 caracteres",
    ],
    headers: [
      { text: "Order Id", value: "order_id_task", align: "start" },
      { text: "Items", value: "items" },
    ],
    regionesRule: [
      (v) => !!v || "Campo requerido",
      (v) => (v && v.length >= 6) || "Debes seleccionar una región",
    ],
    arregloFulFillment: [],

    equipoMigps: "",
    equipoMigpsRules: [(v) => !!v || "Se requiere código de equipo"],
    // select: 0,
    // items: [1, 2, 3, 4],
    nombreCampo: "added_kits",

    textoConfirmacion: "",
    color: "warning",
    statusLlamada: 0,
  }),

  methods: {
    // validate() {
    //   this.$refs.form.validate();
    // },
    reset() {
      this.$refs.form.reset();
      this.equipoMigps, (this.OrderId = "");
      this.alerta = false;
      this.alertaNegativa = false;
    },
    async agregarEquipoMigps() {
      this.sendingRequest = true;
      console.log(this.datosUsuario());
      try {
        console.log("Equipo Migps: " + this.equipoMigps);

        this.alerta = false;
        this.alertaNegativa = false;

        let orderId = this.OrderId;
        let equipos = this.equipoMigps;
        let idCustomer = "6099a6de2f22fc6ae8af82bc";
        console.log("Order Id: " + orderId);
        var dataArreglo = { order_id_task: orderId, items: equipos.split(",") };
        const respuesta = await axios.get(
          `https://backendservices.rayoapp.com/Task-Groups/tasks_group/filters/by?idOrder=${orderId}&idCustomer=${idCustomer}`,
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        //console.log(respuesta.data[0].tasks[0]._id);
        for (const n in respuesta.data[0].tasks) {
          var idTarea = respuesta.data[0].tasks[n]._id;
          //  var orderId = respuesta.data[0].tasks[n].order_id_task;
          var equipoMigpssSeparados = equipos.split(",");
          var arregloequipoMigpss = [];
          for (let i = 0; i < equipoMigpssSeparados.length; i++) {
            arregloequipoMigpss[i] = equipoMigpssSeparados[i];
          }
          // arregloequipoMigpss[] = equipoMigpssSeparados;
          console.log(arregloequipoMigpss);

          var data = {
            field: this.nombreCampo,
            data: arregloequipoMigpss,
          };
        }

        const respuestaIdTarea = await axios.put(
          `https://backendservices.rayoapp.com/Tasks/tasks/${idTarea}/add_metadata`,
          data,
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("Status: " + respuestaIdTarea.status);
        this.statusLlamada = respuestaIdTarea.status;

        let newLabel = { orderID: orderId, email: this.datosUsuario() };
        //  console.log(newLabel);

        const respuestaEtiqueta = await axios.post(
          `https://southamerica-east1-rayo-api.cloudfunctions.net/funcionesRayo/funcionesRayo/GeneraEtiquetaMigpsFulFillment`,
          newLabel
        );

        if (this.statusLlamada === 200 && respuestaEtiqueta.status === 200) {
          // envio el estadi fulFillment a cliente a traves del proxy
          let dataFulFillment = {
            fullDocument: {
              order_id_task: orderId,
              id_estado: "fullFilment",
              series: arregloequipoMigpss,
            },
          };

          console.log("dataFulFillment: " + JSON.stringify(dataFulFillment));
          try {
            let fullFillment = await axios.post(
              "https://ris.rayoapp.com/migps/proxy",
              dataFulFillment
            );
            console.log(
              "respuesta del proxy fulFillment:" +
                JSON.stringify(fullFillment.data)
            );
          } catch (error) {
            console.log("error el informar estado fulfillment");
          }

          this.alerta = true;
          this.sendingRequest = false;
        }
        // if (this.statusLlamada === 200) {
        //   this.alerta = true;
        //   this.sendingRequest = false;
        // }

        this.arregloFulFillment.push(dataArreglo);
      } catch (error) {
        this.alertaNegativa = false;
        console.log(error);
        this.alertaNegativa = true;
      }
      this.sendingRequest = false;
    },
    datosUsuario() {
      return firebase.auth().currentUser.email;
    },
  },
  computed: {
    deshabilitar() {
      return this.OrderId === "" || this.equipoMigps === "";
    },
    deshabilitarBotonPdf() {
      if (this.clienteEtiqueta == "5fb7baf4c8c6410667c3f1bb") {
        return this.clienteEtiqueta == "" || this.tipoEtiqueta == "";
      } else {
        return this.clienteEtiqueta == "";
      }
    },
  },
};
</script>

<style scoped>
a.etiquetas {
  text-decoration: none;
  color: white;
}
/* .pad {
  pa
} */
</style>
